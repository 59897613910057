.close-button-disabled {
  color: lighten(grey, 30%);
  cursor: not-allowed;
  box-shadow: none;
}

.close-button {
  padding: 0 10px;
  &__button {
    display: block;
    width: 99%;
    padding: 10px 5px;
    margin: 2px auto 2px;
    color: white;
    background-color: lighten(grey, 20%);
    outline: none;
    border: 1px solid lighten(grey, 20%);
    font-family: IRANSansWeb;
    font-size: 0.9rem;
    border-radius: 40px;
    transform: scale(0.95);
    transition:
      transform ease 0.2s,
      box-shadow ease 0.2s;
    cursor: pointer;
    &:hover,
    &:active {
      transform: scale(1);
      box-shadow: 0 2px 1px 1px darken(grey, 10%);
    }

    &[disabled] {
      @extend .cancel-button-disabled;
      background-color: darken(grey, 10%);
    }
  }
}

.auth-modal {
  height: 100%;
  @extend .flexbox-start-start;
  flex-direction: column;
  .title {
    display: flex;
  }
  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  .auth-access {
    margin: 10px 0 10px 0;
    width: 100%;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 5px;
    font-size: 0.9rem;
    @extend .flexbox-space-between-stretch;
    flex-direction: column;
  }
  .chip {
    font-size: 0.7rem;
    background-color: white;
    padding: 3px 7px;
    border-radius: 15px;
    border: 1px solid $border-color;
    display: inline-block;
    margin: 5px;
    .small-icon {
      font-size: 0.7rem;
    }
  }
}

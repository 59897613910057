.delete-button-disabled {
  color: lighten(red, 30%);
  cursor: not-allowed;
  box-shadow: none;
}

.delete-button {
  padding: 0 10px;
  &__button {
    display: block;
    width: 99%;
    padding: 10px 5px;
    margin: 2px auto 2px;
    color: red;
    background-color: white;
    outline: none;
    border: 1px solid red;
    font-family: IRANSansWeb;
    font-size: 0.9rem;
    border-radius: 40px;
    transform: scale(0.95);
    transition:
      transform ease 0.2s,
      box-shadow ease 0.2s;
    cursor: pointer;
    &:hover,
    &:active {
      transform: scale(1);
      box-shadow: 0 2px 1px 1px darken(red, 10%);
    }

    &[disabled] {
      @extend .delete-button-disabled;
      background-color: darken(red, 10%);
    }
  }
}

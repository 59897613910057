.owas-add-photo {
  form {

    @include media-break(md, down) {
      display: block;
    }
  }

  .upload-image-button {
    margin-top: 1rem;

    @include media-break(md, down) {}

  }

  .show_upload_photo {
    display: flex;
    margin: 20px;
  }

  .select-chip {
    margin-top: 0px;
  }

}
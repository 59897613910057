.add-comment-modal__wrapper {
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  form {
    .checkbox-wrapper {
      margin: 10px 15px;
      display: flex;
    }
  }
  .add-comment-modal-header {
    position: sticky;
    top: 0;
    z-index: 2;
    height: 50px;
    background-color: $primary-color;
    width: 100%;
    justify-content: space-between;
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
    .action-wrappers {
      display: flex;
      button {
        font-size: map-get($font-size, "md");
      }
    }
    .title {
      margin: 0 10px 0 0;
    }
  }
  .title {
    display: flex;
    font-size: map-get($font-size, "md");
    color: white;
  }
  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 20px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }
  .content-wrapper {
    background-color: lighten($border-color, 10%);
    margin: 10px 5%;
    border-radius: 5px;
    border: 1px solid darken($border-color, 5%);
    overflow: hidden;

    div {
      display: flex;
      padding: 5px 20px;
      justify-content: space-between;
      border-bottom: 1px solid darken($border-color, 5%);
      font-size: map-get($font-size, "md");

      span {
        color: $tertiary-color;
        font-size: map-get($font-size, "md");
        margin: 0 0 0 5px;
        display: contents;
      }
      div {
        color: $input-color;
        font-size: map-get($font-size, "md");
        display: contents;
      }
    }
    div:last-child {
      border-bottom: unset;
      border-radius: 0 0 10px 10px;
    }
    div:first-child {
      border-radius: 10px 10px 0 0;
    }

    div:nth-child(even) {
      background: lighten($border-color, 5%);
    }
    div:nth-child(odd) {
      background: lighten($border-color, 10%);
    }
  }
}
.comment-modal__message {
  text-align: center;
  color: $input-color;
}

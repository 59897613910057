.datepicker-input {
  padding: 8px;
  border: 1px solid $primary-color;
  backdrop-filter: blur(4px);
  border-radius: 5px;
  width: 100%;
  align-self: center;
  background-color: $pure-white;
  outline: none;
  transition: border ease 0.2s;
  font-family: IRANSansWeb;
  transition: all ease 0.3s;
  display: flex;
  flex-direction: column;
  &:focus {
    box-shadow: 0px 0px 15px -3px $primary-color;
  }
  &--error {
    border-color: red;
    box-shadow: 0px 0px 8px -3px red;
    &:focus {
      box-shadow: 0px 0px 8px -3px red;
    }
  }
  &--icon {
    padding: 8px 38px 8px 8px;
  }
}

body {
  .tether-element {
    @include media-break(md, down) {
      top: 50% !important;
      left: 50% !important;
      transform: translateX(-50%) translateY(-50%) !important;
    }
  }
}
.calendarContainer {
  .heading {
    .title {
      background-color: lighten($primary-color, 10%);
      &:hover {
        background-color: darken($primary-color, 10%);
      }
    }
    .prev &:hover {
      border-radius: 0;
    }
    .next &:hover {
      border-radius: unset;
    }
  }
  button {
    font-size: 0.8rem !important;
  }
  .selectToday {
    border: 1px solid $primary-color !important;
    background-color: $pure-white;
    color: $primary-color;
  }
  .selectToday:hover {
    border: 1px solid $primary-color !important;
    background-color: $primary-color;
    color: $pure-white;
  }

  div.selected {
    button {
      background-color: $primary-color !important;
      color: $pure-white !important;
    }
  }
  input {
    font-size: 0.8rem !important;
    &:active,
    :focus {
      outline: none;
    }
  }

  .heading > button {
    padding: 8px;
  }
  .dayWrapper:not(.selected) button:hover {
    color: $pure-white !important;
    background-color: $primary-color !important;
  }
  .today button {
    border: none !important;
  }

  .dayWrapper button[disabled] {
    background-color: $pure-white !important;
  }
  .dayWrapper button {
    color: $pure-black;
  }
}

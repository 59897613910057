.table-container {
  margin-top: 10px;
 height: calc(100vh - 24rem);

 @include media-break(md, down) {
      height: 800px;
    }

  .delete-icon {
    font-size: 1rem;
    color: red;
    cursor: pointer;
    padding: 0 3px;
    margin: 0 5px;
  }
  .edit-icon {
    font-size: 1rem;
    color: $tertiary-color;
    padding: 0 3px;
    cursor: pointer;
    margin: 0 5px;
  }

  .comment-icon {
    cursor: pointer;
    margin: 0 5px;
  }
  .add-icon {
    cursor: pointer;
    color: $primary-color;
    margin: 0 5px;
    font-size: 1rem;
  }
}

.table-container.table-long {
  height: 600px;
  @include media-break(md, down) {
    height: 300px;
  }
}

.table-container.table-double-long {
  height: 800px;
  @include media-break(md, down) {
    height: 600px;
  }
}

.ag-row:hover {
  color: rgb(75, 75, 75) !important;
}
.ag-cell-inline-editing,
.ag-cell-inline-editing:hover {
  color: black !important;
}
.ag-text-field-input,
.ag-text-field-input:hover {
  color: black !important;
}
.ag-header-container {
  background-color: lighten($primary-color, 12%);
}
.ag-header {
  background-color: lighten($primary-color, 12%) !important;
}
.ag-header-cell {
  color: $pure-white;
  font-family: IRANSansWeb;

  span {
    color: $pure-white;
  }
}

.ag-icon-menu {
  &::before {
    color: $pure-white;
  }
}
.ag-center-cols-viewport {
  background-color: $pure-white;
}

.ag-root-wrapper {
  border-radius: 5px 5px 0 0;
}

.ag-cell {
  color: $input-color;
  font-family: IRANSansWeb;
  &:focus {
    outline: none;
  }
  &:first-child,
  &:nth-child(2) {
    color: $pure-black;
  }
}

.ag-row {
  &:hover {
    background-color: #ffcd80 !important;
    color: $pure-white !important;
    .ag-cell {
      color: $pure-white;
      font-weight: bold;
    }
  }
}
.ag-theme-alpine .ag-row-odd {
  background-color: #fcfcfc;
  background-color: var(--ag-odd-row-background-color, #ffead7);
}

.photo-cropper {
  height: 100%;
  @extend .flexbox-space-between-stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  &__message {
    text-align: center;
    color: $input-color;
    margin-top: 10px;
  }
  &__croper-wrapper {
    position: relative;
    min-height: 100px;
    @extend .flexbox-center-center;

    @include media-break(lg, down) {
      min-height: 50px;
      flex-wrap: wrap;
    }
    div.crop-wrapper {
      flex-basis: 500%;
      text-align: center;
      border: 1px solid $primary-color;
      padding: 10px;
      background-color: #e5e5e5;
      margin: 10px;
      @include media-break(lg, down) {
        flex-basis: 100%;
      }
    }
    div.canvas-wrapper {
      flex-basis: 500%;
      text-align: center;
      border: 1px solid $primary-color;
      align-self: stretch;
      @extend .flexbox-center-center;
      margin: 10px;
      padding: 5px;
      @include media-break(lg, down) {
        flex-basis: 100%;
      }
    }
  }

  &__button-wrapper {
    @extend .flexbox-center-center;
    @include media-break(xs, down) {
      flex-wrap: wrap;
      flex-direction: row-reverse;
    }
  }
  small{
    display: flex;
    justify-content: center;
    margin-top: 8px;
  }
}
.button-wrapper {
  flex: 1 1 auto;
  @include media-break(xs, down) {
    flex-basis: 100%;
  }
}

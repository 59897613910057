.react-table-container {
  margin-top: 10px;
}

.react-table {
  border: 1px solid $border-color;
  margin: 10px 5px;
  width: 100%;
}
.react-table-header {
  background-color: $primary-color;
  color: $pure-white;
  height: 43px;
  font-size: $font-size-base;
}
.react-table-body {
  tr td {
    word-break: break-all;
    text-align: center;
    font-size: map-get($font-size, "md+");
  }
  tr:nth-child(even) {
    background-color: lighten($border-color, 10%);
  }
}

.telecom-add-range {

    .--top-space {
        margin-top: 1rem;
    }

    .range-wrapper {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }

    .range-item {

        background: #ededed;
        border: 1px solid #dbdbdb;
        border-radius: 18px;
        margin-left: 16px;
        padding: 4px 8px;
        font-size: 14px;
        color: #0079ff;
        margin-top: 16px;
        display: flex;
        width: -moz-fit-content;
        width: fit-content;
        align-items: center;
        cursor: pointer;
     }

    .range-item:last-child {
        border: none;
    }

    .select-wrapper {
        margin-top: 16px;
    }
}
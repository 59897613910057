.delete-modal__wrapper {
  height: 100%;
  @extend .flexbox-space-between-stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  .title {
    display: flex;
    align-items: center;
  }
  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }
}
.delete-modal__message {
  text-align: center;
  color: $input-color;
  margin-top: 1rem;
}

.delete-modal__button-wrapper {
  display: inline-block;
  &--item {
    width: 50%;
    display: inline-block;
    @include media-break(sm, down) {
      display: block;
      width: 100%;
    }
  }
}

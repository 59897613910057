$point-list: (
  "xs": 480px,
  "sm": 640px,
  "md": 800px,
  "lg": 960px,
  "xl": 1120px,
  "xxl": 1280px,
  "xxxl": 1440px,
);

$font-size-base: 0.875rem; // Assumes the browser default, typically `15px`
$font-size-lg: ($font-size-base + 0.25); //18px
$font-size-sm: ($font-size-base - 0.125); //12px
$font-size: (
  "xxs": 0.5rem,

  "xs": 0.625rem,

  "sm": $font-size-sm,
  "md": 0.6875rem,

  "md+": 0.8875rem,

  "lg": $font-size-lg,

  "xl": 1.25rem,

  "xxl": 1.5rem,

  "xxxl": 1.75rem,

  "4xl": 1.875rem,

  "xlxl": 2.5rem,

  "cp": 1rem,
);

$font-weight_base: 400;
$font-weight: (
  "lighter": lighter,
  "light": 300,
  "normal": 400,
  "bold": 700,
  "bolder": bolder,
  "thin": 100,
  "medium": 500,
  "black": 900,
);

$primary-color: #ff7d00;
$secondary-color: #f5cb5c;
$pure-white: #ffffff;
$pure-black: #000000;
$input-color: #535353;
$nav-color: #5a5a5a;
$border-color: #d5d5d5;
$tertiary-color: #0079ff;
$falsy-color: #ff3700;
$excel-color: rgb(50, 119, 50);

$main-content-max-width: map-get($point-list, "xxxl");

$border-light-grey: 1px solid lighten($input-color, 50%);

$brand-ratio: 250 * 0.003846154;
$primary-slide-ratio: 1200 * 0.003125;
$secondary-slide-ratio: 250 * 0.003125;
$special-package-ratio: 750 * 0.002898551;
$instagram-ratio: 150 * 0.005263158;
$product-ratio: 1;

$drawer-width: 250px;

@import "./variables";
@import "./breakpoints";
@import "./utility";
@import "./font-faces";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "./base.scss";
@import "./components/header.scss";
@import "./components/input.scss";
@import "./components/submit-button.scss";
@import "./components/submit-button-secondary.scss";
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/dist/styles/ag-theme-alpine.css";
@import "./components/pagination.scss";
@import "./components/table.scss";
@import "./components/no-item.scss";
@import "./components/react-select.scss";
@import "./components/cancel-button.scss";
@import "./components/delete-button.scss";
@import "./components/delete-modal.scss";
@import "./components/comment-modal.scss";
@import "./components/close-button.scss";
@import "./components/image-wrapper.scss";
@import "./components/file-catcher.scss";
@import "./components/upload-button.scss";
@import "./components/photo-cropper.scss";
@import "./components/auth-guide.scss";
@import "./components/auth-by-modal.scss";
@import "./components/calender.scss";
@import "./components/add-comment.scss";
@import "./components/react-table.scss";
@import "react-loading-skeleton/dist/skeleton.css";
//views style
@import "../../views/dashboard/main/owas/owas.scss";
@import "../../views/dashboard/main/faults/fault.scss";
@import "../../views/dashboard/main/telecoms/telecom.scss";

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: IRANSansWeb, sans-serif;
  direction: rtl;
  overflow-x: hidden;
  background-color: #f5f5f5;
  position: relative;
}
* {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}


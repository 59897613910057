.main-image-wrapper {
  background-color: $pure-white;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px;
  border: 1px solid $border-color;
  button {
    cursor: pointer;
  }

  .images-wrapper--high {
    padding-right: 25px;
    padding-bottom: 20px;
    width: 50%;
    margin-left: auto !important;
    label {
      display: inline-block;
      margin: 10px 0 5px;
    }
    @include media-break(md, down) {
      width: 100%;
    }
  }
}
.swiper-main-wrapper {
  background-color: lighten($input-color, 65%);
  width: unset !important;
  margin: 15px 0px;
  border-radius: 10px;
  border: 1px solid $border-color;
  .delete-icon {
    font-size: 1rem;
    color: white;
    cursor: pointer;
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 99;
    background-color: #ffd1d194;
    padding: 8px;
    border-radius: 5px 0 5px 0;
    &:hover {
      color: red;
    }
  }
}

.swiper-slide img {
  border-radius: 10px;
}

.swiper {
  height: 100%;
  padding: 20px 45px !important;
  z-index: 0 !important;
  .swiper-button-next::after {
    color: lighten($input-color, 35%) !important;
  }
  .swiper-button-prev::after {
    color: lighten($input-color, 35%) !important;
  }
}
.swiper-container {
  width: 480px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  border-radius: 10px;
  position: relative;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@font-face {
  font-family: "IRANSansWeb";
  src: url("./fonts/IRANSansWeb(FaNum).eot");
  src:
    local("IRANSansWeb"),
    url("./fonts/IRANSansWeb(FaNum).woff2") format("woff2"),
    url("./fonts/IRANSansWeb(FaNum).woff") format("woff"),
    url("./fonts/IRANSansWeb(FaNum).ttf") format("truetype"),
    url("./fonts/IRANSansWeb.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

.fault-add-photo {
  form {

    @include media-break(md, down) {
      display: block;
    }
  }

  .upload-image-button {
    margin-top: 1rem;

    @include media-break(md, down) {}

  }

  .show_upload_photo {
    display: flex;
    margin: 20px;
  }

  .select-chip {
    margin-top: 0px;
  }

}

.fault-in-telecom {

  .no-flex-end {
    justify-content: flex-start;
  }

  .show-users-list-button {
    border: none;
    background-color: unset;
    color: $primary-color;
    font-family: IRANSansWeb;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid $primary-color;
    border-radius: 8px;
    padding: 4px 12px;
  }

  .statics-wrapper {
    margin: 2rem 0px;
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .static-item {
      cursor: pointer;
      border: 1px solid #d2d2d2;
      background-color: #ededed;
      border-radius: 16px;
      margin: 4px 6px;
      font-size: map-get($font-size, "md");
      color: $tertiary-color;
      padding: 4px 8px;
      display: flex;

      span {
        color: $primary-color;
        margin-right: 4px;
      }
    }

    .user-statistic-wrapper {
      display: flex;
      flex-wrap: wrap;
      margin-left: 32px;
      width: 100%;
      margin-bottom: 8px;
    }
    .static-item-active {
      border-color: $tertiary-color;
    }
  }
}

.change-status-modal-wrapper{
form{  padding-bottom: 1rem;}

.change-status-modal{
  padding: 20px 10px;
  @extend .flexbox-start-stretch;
  flex-wrap: wrap;
  margin: auto auto;
  flex-direction: column;
  border-radius: 10px;
 
  .skeletonWrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .react-loading-skeleton {
    margin: 0px 8px;
    width: 46%;
    @include media-break(md, down) {
      width: 100%;
    }
  }

  }
}
.status-list{
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 1rem;
  justify-content: space-between;
  @include media-break(sm, down) {
   display: block;
   label{
    margin-top: 4px;
    margin-left: 8px;
   }
  }

}

.download-form-wrapper{
  max-width: 1196px;
  margin: 0 auto;
  padding: 24px 0;

  .title-wrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    .date{
      font-size: 12px;
     
    }
  }
  .form{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    font-size: small;
    p,label{
      font-size: small;
    
    }
   
    .title-hint{
      width:100%;
      margin-top: 0.5rem;
      font-weight: bold;
      font-size: small;
      text-align: center;
      margin-bottom: 0.5rem;
    }
    .checkbox-wrapper{
      font-size: small;
      display: flex;
      align-items: center;
      margin: 8px 0px;
    }
    .modem-check-title{
      display: flex ;
      align-items: center;
    
      font-size: small;
      text-wrap: nowrap;
      input{
        margin:0px 8px
      }
    }
    .button-wrapper{
      display: flex;
      justify-content: center;
      margin-top: 2rem;
      width: 100%;
      button{
        cursor: pointer;
        width:160px;
        border:1px solid #d2d2d2;
        border-radius: 8px;
        padding: 8px 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .pdf-icon{
        font-size: 32px;
margin-left: 8px;
      }
    }
    hr{
      width: 100%;
      border-style: solid;
      margin-top:1rem;
      border-color: #d2d2d2;
    }
    .sigCanvas{
      width: 100%;
      height: 160px;
      border: 1px solid #d2d2d2;
      border-radius: 8px;
      margin-top: 1rem;
    }
    .full-width{ 
      width: 100% !important;
     }

    .form-item, .input-wrapper{
      
display: block !important;
padding: 0px 18px;
      width: 48%;
      margin:0px 0;
      @include media-break(sm, down) {
        width: 100%;
      }
      .input-label{
        padding-right: 0px;
        font-size: smaller;
        padding-top: 8px;
      }
      .input{
        align-self: flex-start;
        width: 100%;

      }
    }
    .input-items-wrapper{
      width:100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 1rem;
      .item{
        margin-top:8px;
        font-size: small;
        width: 48%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include media-break(sm, down) {
          width: 100%;
        }
        span{
          margin-left: 8px;
          font-size: small;
        }
      }
    }
  }
}
.download-btn{
  border:1px solid $tertiary-color;
  border-radius: 0.5rem;
  padding: 0.1rem 1rem;
  font-size: small;
  color:$tertiary-color;
  margin:0px 1rem;
}
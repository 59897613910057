.input-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  i {
    position: absolute;
    right: 6%;
    top: 50px;
    font-size: map-get($font-size, "xl");
    color: $primary-color;
    z-index: 1;

    @include media-break(md, down) {
      right: 7%;
    }

    @include media-break(xs, down) {
      right: 8%;
    }
  }
}

.triple-input-wrapper {
  display: flex;

  &--high {
    margin: 25px 0;
    width: 93%;
    margin: auto;

    .input-label {
  
      padding: 10px 2% 4px 0px;
 
    }
    input {
      font-family: IRANSansWeb;
      font-size: 0.8rem !important;
    }
  }
}

.pair-input-wrapper {
  display: flex;
  align-items: flex-end;


  .input-wrapper {
    flex: 1
  }

  .submit-button-secondary {
    width: 8rem
  }

}

.input-label {
  display: block;
  font-size: map-get($font-size, "md+");
  padding: 10px 5% 5px 0;
  color: $input-color;
}

.input {
  padding: 8px;
  border: 1px solid $primary-color;
  backdrop-filter: blur(4px);
  border-radius: 5px;
  width: 90%;
  align-self: center;
  background-color: $pure-white;
  outline: none;
  transition: border ease 0.2s;
  font-family: IRANSansWeb;
  transition: all ease 0.3s;
  display: flex;
  flex-direction: column;

  &:focus {
    box-shadow: 0px 0px 15px -3px $primary-color;
  }

  &--error {
    border-color: red;
    box-shadow: 0px 0px 8px -3px red;

    &:focus {
      box-shadow: 0px 0px 8px -3px red;
    }
  }

  &--icon {
    padding: 8px 38px 8px 8px;
  }
}

.input-error {
  display: block;
  font-size: 0.8rem;
  padding: 5px 5% 0 0;
  color: red;

  &--select {
    @extend .input-error;
    padding: 5px 1%;
    margin: 0;
  }
}

input[type="tel" i] {
  direction: rtl;
}
.no-item-wrapper {
  text-align: center;
  border: 1px dashed $border-color;
  margin: 0 auto;
  margin-top: 20px;
  font-size: 0.9rem;
  color: $primary-color;
  padding: 15px 0;
  background-color: $pure-white;
  div {
    color: $primary-color;
    font-size: map-get($font-size, "lg");
  }
  img {
    width: 100%;
  }
  i {
    font-size: 1.2rem;
    margin: 0 10px;
  }
}

.flexbox-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexbox-start-start {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.flexbox-space-between-start {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.flexbox-space-between-center {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.flexbox-space-between-stretch {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.flexbox-start-center {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flexbox-end-center {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flexbox-start-stretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.flexbox-center-stretch {
  display: flex;
  justify-content: center;
  align-items: stretch;
}
.flexbox-center-space-between {
  display: flex;
  justify-content: center;
  align-items: space-between;
}

.flexbox-center-start {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.main {
  max-width: $main-content-max-width;
  margin: 0 auto;
  min-height: calc(100vh - 70px); //main - header - footer
  padding: 25px 10px;
}

.drawer-item {
  padding: 10px 20px 10px 0;
  @extend .flexbox-start-center;
  cursor: pointer;
  position: relative;
  transform-origin: center;
  border: 2px solid transparent;
  margin: 5px;
  border-radius: 5px;
  &:hover {
    color: "#FF9B41";
  }

  i {
    font-size: 1.2rem;
  }
  p {
    font-size: 0.8rem;
    padding: 0 15px;
  }
}

.drawer-wrapper__top {
  @extend .flexbox-start-center;
  justify-content: space-between;
  padding: 15px 15px 15px 10px;
  border-radius: 10px;
  background-color: $primary-color;
  border-width: 1px 1px 0px 1px;
  @include media-break(md, down) {
    border: none;
  }
  i {
    font-size: 2.5rem;
    color: $pure-white;
  }
  &-right {
    i {
      font-size: map-get($font-size, "xxl");
      cursor: pointer;
    }
  }
  &-left {
    display: flex;
    padding: 0 10px;
    div {
      padding: 0 10px;
    }
    h5 {
      text-align: right;
      color: $pure-white;
      margin-bottom: 5px;
    }

    h6 {
      text-align: right;
      color: $pure-white;
    }
  }
  .exit-icon {
    font-size: $font-size-lg;
    color: $pure-white;
  }
  img {
    vertical-align: middle;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: $border-light-grey;
  }
}
.drawer-wrapper__nav {
  background-color: $pure-white;
  .disabled-nav-link {
    pointer-events: none;
  }
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    .sub-li {
      padding-right: 10px;
      border: none;
      a {
        border-bottom: $border-light-grey;
      }
      &:first-of-type {
        a {
          border-top: none;
        }
      }
    }

    .collapse-li {
      @extend .drawer-item;
      position: relative;
      i:last-of-type {
        position: absolute;
        left: 5px;
        padding: 0 5px 0 0;
        transition: transform ease 0.2s;
        font-size: 1rem;
      }
      i.open {
        transform: rotateZ(180deg);
      }
    }
    a {
      @extend .drawer-item;
    }
  }
}

.Toastify__toast-body {
  font-family: IRANSansWeb;
  font-size: $font-size-base;
}

.ril__image {
  transform: none !important;
  max-width: 50% !important;

  @include media-break(md, down) {
    max-width: 80% !important;
  }
}

.main-login {
  @extend .main;
  @extend .flexbox-center-center;

  .main-login__wrapper {
    width: 100%;
    padding: 20px 0 30px 0;
    max-width: map-get($point-list, "lg");
    background-color: $pure-white;
    overflow: hidden;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
    align-items: center;
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.22);
    border-radius: 12px;

    .header-logo {
      padding: 0 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .header-logo__wrapper {
      margin: auto;
      width: 100%;
      padding: 10px 0;

      a {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 5px;
      }

      img {
        max-width: 160px;
        height: auto;
        margin: auto;
      }
    }

    form {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: map-get($point-list, "sm");
    }

    h6 {
      width: 100%;
      max-width: map-get($point-list, "xs");
      text-align: center;
      font-size: map-get($font-size, "md+");
      font-weight: map-get($font-weight, "normal");
      padding: 0 20px;
    }

    a {
      width: 100%;
      max-width: map-get($point-list, "xs");
      text-align: center;
      font-size: map-get($font-size, "md+");
      font-weight: map-get($font-weight, "normal");
      padding: 0 20px;
      color: $primary-color;
    }

    .timer-wrapper {
      width: 90%;
      padding: 2px 5px;
      margin: 0px auto;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: $primary-color;

      .timer-restart-btn {
        border: none;
        background-color: $pure-white;
        cursor: pointer;
        color: $primary-color;
        font-family: IRANSansWeb;
        font-size: $font-size-base;
        font-weight: map-get($font-weight, "normal");
        padding: 0;
      }

      button:disabled {
        cursor: not-allowed;
        color: rgb(148, 148, 148) !important;
      }
    }
  }
}

.dashboard-wrapper {
  @extend .main;
  @extend .flexbox-start-start;

  .drawer-wrapper {
    flex-basis: $drawer-width;
    flex-shrink: 0;
    align-self: stretch;
    background-color: $pure-white;
    border-radius: 10px;
    border: 1px solid $border-color;

    @include media-break(md, down) {
      display: none;
    }

    li {
      color: #626262;
      font-weight: bold;

      span {
        color: #ff7d00;
        font-size: 14px;
        border-radius: 6px;
        text-align: center;
        padding: 3px 6px;
      }
    }
  }

  .upload-wrapper {
    position: relative;
  }

  .upload-file {
    position: absolute;
    inset: 0;
    width: 100%;
    z-index: 0;
    opacity: 0;
  }
}

.drawer-wrapper--mobile {
  width: $drawer-width;
  position: fixed;
  top: 0;
  right: 0;
  background-color: $pure-white;
  height: 100vh;
  overflow: scroll;
  z-index: 999;
  transform: translate($drawer-width + 50px);
  transition: transform 0.3s ease-in;
  border: 1px solid $border-color;
  border-radius: unset;

  &__back {
    padding: 20px 25px;
    color: lighten($input-color, 25%);
    font-size: 1.5rem;
    cursor: pointer;
  }

  @include media-break(md, up) {
    display: none;
  }

  @extend .drawer-wrapper;
}

.drawer-open {
  transform: translate(0);
}

.dashboard-wrapper {
  @extend .main;
  @extend .flexbox-start-start;

  .drawer-wrapper {
    flex-basis: $drawer-width;
    flex-shrink: 0;
    align-self: stretch;
    background-color: $pure-white;
    border-radius: 10px;

    @include media-break(md, down) {
      display: none;
    }
  }

  .main-dashboard {
    flex: 1 1 auto;
    margin: 0px 10px 0;
    overflow-x: auto;
  }

  .main-route-wrapper {
    background-color: $pure-white;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c7c7c7;
  }
}

.list-top {
  @extend .flexbox-space-between-center;

  @include media-break(md, down) {
    display: block;
  }

  padding: 0 4px;

  a {
    max-width: 300px;

    button {
      cursor: pointer;

      @include media-break(md, down) {
        margin-top: 1rem;
        width: 100%;
      }
    }
  }

  i {
    margin: 0 10px;
    color: $tertiary-color;
    font-size: map-get($font-size, "md+");
    cursor: pointer;
  }

  .excel-icon {
    font-size: map-get($font-size, "xl");
    color: $excel-color;
    cursor: pointer;
    margin: 0 5px;
  }

  p::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  p {
    display: flex;
    align-items: center;
    font-size: map-get($font-size, "md+");

    @include media-break(md, down) {
      font-size: map-get($font-size, "sm");
    }

    span {
      padding: 0 5px;
      font-size: map-get($font-size, "lg");
      font-weight: map-get($font-weight, "bold");

      color: $primary-color;

      @include media-break(md, down) {
        font-size: map-get($font-size, "md+");
      }
    }
  }

  .submit-button {
    button {
      background-color: $tertiary-color;
      color: $pure-white;
      border-radius: 10px;
      padding: 8px 45px;
      border: unset;
      font-family: IRANSansWeb;
      font-size: map-get($font-size, "md+");
      font-weight: map-get($font-weight, "normal");
      cursor: pointer;
      margin: 5px 0;

      @include media-break(md, down) {
        padding: 8px 20px;
        font-size: map-get($font-size, "md");
      }
    }
  }

  .statics-wrapper {
    flex: 1;
    display: flex;
    flex-wrap: wrap;

    .static-item {
      cursor: pointer;
      border: 1px solid #d2d2d2;
      background-color: #ededed;
      border-radius: 16px;
      margin: 4px 6px;
      font-size: map-get($font-size, "md");
      color: $tertiary-color;
      padding: 4px 8px;

      span {
        color: $primary-color;
        margin-right: 4px;
      }
    }

    .static-item-active {
      border-color: $tertiary-color;
    }
  }
  .download-btn{
    border:1px solid $tertiary-color;
    border-radius: 0.5rem;
    padding: 0.5rem 1rem;
    font-size: small;
    color:$tertiary-color;
  }
}

.add-item-wrapper {
  padding: 20px 10px;
  background-color: $pure-white;
  border: $border-light-grey;
  @extend .flexbox-start-stretch;
  flex-wrap: wrap;
  margin: auto auto;
  flex-direction: column;
  border-radius: 10px;

  .title {
    margin: 0 25px;
    display: flex;
    align-items: center;
  }

  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  .select-chip {
    display: flex;
    flex-flow: wrap;
    width: 90%;
    margin: auto;

    div {
      padding: 2px 12px;
      background-color: $tertiary-color;
      color: $pure-white;
      border-radius: 50px;
      margin: 3px;
      cursor: pointer;
      font-size: map-get($font-size, "md+");
    }
  }

  .title {
    margin: 0 25px;
    display: flex;
    align-items: center;
  }

  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  form {
    display: grid;

    div:nth-child(odd) {

      grid-column-start: 1;

      @include media-break(md, down) {
        grid-column-end: 3;
      }
    }

    div:nth-child(even) {

      grid-column-start: 2;

      @include media-break(md, down) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    .skeletonWrapper {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }

    .react-loading-skeleton {
      margin: 0px 8px;
      width: 46%;

      @include media-break(md, down) {
        width: 100%;
      }
    }
.status-section{
  position: relative;
  margin:2rem;
  padding: 1rem;
  border:1px solid $tertiary-color;
  border-radius: 8px;
  p{
    font-size: 14px;
    display: list-item;
    margin: 0px 1rem;
  }
  .status-link{
    position: absolute;
    top:1rem;
    left:1rem;
    i{
      color: $tertiary-color;
    }
  }
}
  }

  .submit-button {
    width: 200px;
    margin-right: auto;
    grid-column-start: 1 !important;
    grid-column-end: 3;
  }
}

.equal-columns {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.user-profile-wrapper {
  padding: 20px 10px;
  background-color: $pure-white;
  border: $border-light-grey;
  @extend .flexbox-start-stretch;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;

  .title {
    margin: 0 25px;
    display: flex;
    align-items: center;
  }

  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .input-wrapper {
    flex: 0 0 auto;
    width: 47.25%;

    @include media-break(md, down) {
      width: 100%;
    }
  }

  .input-wrapper:nth-last-child(2) {
    flex: 0 0 auto;
    width: 100%;
  }

  .buttons-wrapper {
    width: 100%;
    @extend .flexbox-center-center;

    .submit-button,
    .submit-button-secondary {
      flex: 0 47%;

      @include media-break(md, down) {
        flex: 0 100%;
      }
    }
  }

  .header-logo {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo__wrapper {
    margin: auto;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    font-size: 8.5rem;
    color: darken($border-color, 4%);

    i {
      cursor: pointer;
    }

    img {
      max-width: 160px;
      height: auto;
      margin: auto;
    }
  }
}

.users_profile-icon {
  font-size: map-get($font-size, "4xl");
}

.change-password-wrapper {
  margin-top: 15px;
  padding: 20px 10px;
  background-color: $pure-white;
  border: $border-light-grey;
  @extend .flexbox-start-stretch;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;

  .title {
    margin: 0 25px;
    display: flex;
    align-items: center;
  }

  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .submit-button {
    width: 100%;
  }

  .input-wrapper {
    flex: 0 0 auto;
    width: 47.25%;

    @include media-break(md, down) {
      width: 100%;
    }
  }

  .notification-button {
    background-color: $primary-color;
    border: none;
    padding: 6px 10px;
    font-size: 0.8rem;
    color: white;
    border-radius: 4px;
    font-family: IRANSansWeb;
    cursor: pointer;
  }

  .buttons-wrapper {
    width: 100%;
    @extend .flexbox-center-center;

    .submit-button,
    .submit-button-secondary {
      flex: 0 47%;

      @include media-break(md, down) {
        flex: 0 100%;
      }
    }
  }

  .header-logo {
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-logo__wrapper {
    margin: auto;
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 5px;
    font-size: 8.5rem;
    color: darken($border-color, 4%);

    i {
      cursor: pointer;
    }

    img {
      max-width: 160px;
      height: auto;
      margin: auto;
    }
  }

  .switch-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1rem;
    padding: 0px 2rem 0 0;
  }

  .section-half {
    flex: 0 0 auto;
    width: 47.25%;

    @include media-break(md, down) {
      width: 100%;
    }

    .input-wrapper {
      width: 100%;
    }

    .submit-button {
      padding-top: 1rem;
    }
  }

  .section-full {
    width: 100%;

    .input-wrapper {
      width: 100%;
    }
  }
}

.upload-wrapper {
  position: relative;
}

.upload-file {
  position: absolute;
  inset: 0;
  width: 100%;
  z-index: 0;
  opacity: 0;
}

.show_upload_photo {
  text-align: center;
  margin: 20px 0 0 0;

  img {
    max-width: 200px;
    width: 100%;
    border-radius: 10px;
  }
}

.user-image {
  position: relative;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  border-radius: 50%;
}

.auth__add {
  padding: 20px 10px;
  background-color: $pure-white;
  border: $border-light-grey;
  @extend .flexbox-start-stretch;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;
  height: calc(100vh - 24rem);
  &--top-wrapper {
    @extend .flexbox-space-between-center;
    flex-wrap: wrap;
    margin: 10px 25px;
   
    label {
      width: 100%;
    }
  }

  &--bottom-wrapper {
    width: 45%;

    @include media-break(md, down) {
      width: 100%;
    }
  }

  &--select-wrapper {
    @include media-break(md, down) {
      width: 100%;
    }

    width: 45%;
    margin: 5px 0;
  }

  .title {
    margin: 0 25px;
    display: flex;
    align-items: center;
  }

  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }
}

.auth-add {
  padding: 5px 10px;
  background-color: $pure-white;
  border: $border-light-grey;
  margin: 10px 0;
  flex-wrap: wrap;
  flex-direction: column;
  border-radius: 10px;

  &__header {
    margin: 0 20px;
    font-size: 0.8rem;
    vertical-align: middle;
  }

  .access {
    @extend .flexbox-space-between-center;

    &.top {
      display: block;
    }

    .row-title {
      vertical-align: middle;
      margin-top: 10px;

      div:first-child {
        vertical-align: middle;
      }
    }

    span:first-child {
      flex-grow: 2;
      padding: 5px;
    }

    .crud-wrapper {
      flex-grow: 2;
      text-align: end;
    }

    .crud {
      @extend .flexbox-center-center;
      display: inline-flex;
      padding: 0 5px;
    }
  }

  .submit__wrapper {
    @extend .flexbox-end-center;
    margin: 10px 0;
  }
}

.question-icon {
  cursor: pointer;
  color: $primary-color;
  margin: 0 5px;
  font-size: 1rem;
  vertical-align: middle;
}

.add-icon {
  cursor: pointer;
  color: $tertiary-color;
  margin: 0 5px;
  font-size: 1rem;
  vertical-align: middle;

  &--large {
    font-size: 1.2rem;
  }
}

.edit-icon {
  font-size: 1rem;
  color: $tertiary-color;
  margin: 0 5px;
  cursor: pointer;

  &--large {
    font-size: 1.2rem;
  }
}

.delete-icon {
  font-size: 1rem;
  color: red;
  cursor: pointer;
  padding: 0 3px;
  margin: 0 5px;

  &--large {
    font-size: 1.1rem;
  }
}

.rotate {
  -webkit-animation: rotate 1s normal linear infinite;
  animation: rotate 1s normal linear infinite;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }

  25% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }

  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }

  75% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }

  100% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
}

.list-input-wrapper {
  padding: 16px 8px;

  .switch-item {
    grid-column-start: 5;
    grid-column-end: 1;
    justify-content: flex-end;
    display: flex;
    padding: 10px 0 0 20px;

    label {
      color: $primary-color;
      font-size: map-get($font-size, "md+");
      margin: 0 5px;
    }
  }

  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  align-items: flex-end;
  justify-content: center;
  background-color: white;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 10px;

  @include media-break(md, down) {
    grid-template-columns: 1fr;
  }

  margin: 10px 0;

  button {
    margin: 0px auto 0px;
    padding: 5px;

    @include media-break(md, down) {
      display: grid;
      grid-column-start: 1;
      grid-column-end: 12;
      margin-top: 1rem;
    }
  }

  label {
    padding-bottom: 0rem;
  }

  .select-wrapper--high {
    input {
      width: 100%;
    }

    @include media-break(md, down) {
      display: grid;
      grid-column-start: 1;
      grid-column-end: 12;
    }
  }

  p::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  p {
    display: flex;
    align-items: center;
    font-size: map-get($font-size, "md+");

    @include media-break(md, down) {
      font-size: map-get($font-size, "sm");
    }

    span {
      padding: 0 5px;
      font-size: map-get($font-size, "lg");
      font-weight: map-get($font-weight, "bold");

      color: $primary-color;

      @include media-break(md, down) {
        font-size: map-get($font-size, "md+");
      }
    }
  }

  .change-assignment-button {
    border: none;
    background-color: unset;
    color: $primary-color;
    font-family: IRANSansWeb;
    font-weight: bold;
    cursor: pointer;
    border: 1px solid $primary-color;
    border-radius: 8px;
    padding: 4px 16px;
    display: flex;
    grid-column-end: none;
    height: 40px;
    align-items: center;
  }
}

textarea {
  resize: none;
}

.main-route-wrapper-top-2 {
  background-color: $pure-white;
  border: 1px solid $primary-color;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  .category-wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .category-span {
    background: #ededed;
    border: 1px solid #dbdbdb;
    border-radius: 18px;
    margin-left: 16px;
    padding: 4px 8px;
    font-size: 14px;
    color: $tertiary-color;
    margin-top: 16px;
    display: flex;
    width: fit-content;
    align-items: center;
    cursor: pointer;
  }

  .category-span-selected {
    border-color: $tertiary-color;
  }

  .submit-button {
    button {
      width: 277px;
      left: 0;
      position: absolute;
      bottom: 0px;
      background-color: $tertiary-color;
      color: $pure-white;
      border-radius: 10px;
      padding: 8px 45px;
      border: unset;
      font-family: IRANSansWeb;
      font-size: map-get($font-size, "md+");
      font-weight: map-get($font-weight, "normal");
      cursor: pointer;
      margin: 5px 0;

      @include media-break(md, down) {
        padding: 8px 20px;
        font-size: map-get($font-size, "md");
      }
    }
  }
}

.sms-top-section {
  background-color: $pure-white;
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #c7c7c7;
  margin-bottom: 16px;
  display: flex;

  @include media-break(md, down) {
    padding: 8px 20px;
    font-size: map-get($font-size, "md");
  }

  gap: 16px;

  .main-route-wrapper-top-1 {
    flex: 1;
  }

  .main-route-wrapper-top-2 {
    flex: 1;
    padding-bottom: 4rem;
  }

  @include media-break(md, down) {
    display: block;
  }
}

.dashboard-sections-wrapper {
  padding: 16px 8px;
  background-color: white;
  border: 1px solid rgb(207, 207, 207);
  border-radius: 10px;
  margin: 10px 0;

  @include media-break(md, down) {
    grid-template-columns: 1fr;
  }

  label {
    padding-bottom: 0rem;
  }

  .input-wrappers {
    padding: 8px 16px;
    display: grid;
    gap: 16px;

    input {
      width: 100%;
    }

    @include media-break(md, down) {
      display: block;
    }

    .select-wrapper-1 {
      grid-column-start: 1;
    }

    .select-wrapper-2 {
      grid-column-start: 2;
    }

    .submit-button {
      display: flex;
      align-items: flex-end;

      button {
        width: 100%;
        margin: 0px;
        padding: 5px;
      }

      @include media-break(md, down) {
        margin-top: 1rem;
      }

      grid-column-start: 3;
    }
  }

  .info-head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;

    .date {
      color: $primary-color;
    }

    @include media-break(md, down) {
      display: block;
    }
  }

  .items-wrapper {
    padding: 8px 16px;
    display: grid;
    gap: 16px;

    div:nth-child(odd) {
      grid-column-start: 1;

      @include media-break(md, down) {
        grid-column-end: 3;
      }
    }

    div:nth-child(even) {
      grid-column-start: 2;

      @include media-break(md, down) {
        grid-column-start: 1;
        grid-column-end: 3;
      }
    }

    div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: #5a5a5a;
      font-size: 14px;

      span {
        color: black;
        font-size: 16px;
      }
    }
  }

  p::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }

  p {
    display: flex;
    align-items: center;
    font-size: map-get($font-size, "md+");

    @include media-break(md, down) {
      font-size: map-get($font-size, "sm");
    }

    span {
      padding: 0 5px;
      font-size: map-get($font-size, "lg");
      font-weight: map-get($font-weight, "bold");

      color: $primary-color;

      @include media-break(md, down) {
        font-size: map-get($font-size, "md+");
      }
    }
  }

  .react-table-container {
    text-align: center;
  }
}
.access-cell {
  display: flex;
  align-items: center;
  white-space: nowrap;
  max-width: 24rem; 
  overflow: hidden;
  min-width: 180px;
  justify-content: center;
  flex-direction: column;

  &__full-list {
    display: flex;
    flex-wrap: wrap;
align-items: center;
flex-direction: column;
    ul {
      list-style-type: disc;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      li {
        margin-right: 2rem;
        text-align: start;
        font-size: 0.75rem; 
      }
    }

    button {
      margin-right: 0.5rem; 
      color: #3b82f6;
      font-size: 0.75rem; 
      background: none;
      border: none;
      cursor: pointer;
   font-family: inherit;
    }
  }

  &__truncated-list {
    display: flex;
    align-items: center;
    flex-direction: column;
    ul {
      list-style-type: disc;
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: column;
      li {
        text-align: start;
        margin-right: 2rem;
        font-size: 0.75rem; 
      }
    }

    button {
      margin-right: 0.5rem; 
      color: #3b82f6;
      font-size: 0.75rem; 
      background: none;
      border: none;
      cursor: pointer;
      font-family: inherit;
    }
  }
}

.enabled-text{
  font-size: 12px;
  color:green;
  font-weight: bold;
  text-align: center;

}
.disabled-text{
  font-size: 12px;
  color:red;
  font-weight: bold;
  text-align: center;
}
.switch-item {

  justify-content: flex-end;
  display: flex;
  padding: 10px 0 0 20px;

  label {
    color: $primary-color;
    font-size: map-get($font-size, "md+");
    margin: 0 5px;
  }
}
.auth-help {
  margin: 10px 0 10px 0;
  width: 100%;
  background-color: #f5f5f5;
  border-radius: 10px;
  padding: 5px;
  font-size: 0.9rem;
  @extend .flexbox-space-between-stretch;
  flex-direction: column;
}

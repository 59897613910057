#header {
  @extend .flexbox-space-between-center;
  min-height: 70px;
  background: $pure-white;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
  .header-left {
    @extend .flexbox-space-between-center;
    .push-notifications {
      width: 50px;
      height: auto;
      color: $primary-color;
      cursor: pointer;
    }
  }
  .header-hamburger {
    flex-grow: 0;
    @extend .flexbox-start-center;
    i {
      margin: 10px;
      font-size: 1.5rem;
      color: $pure-black;
      cursor: pointer;
    }
    @include media-break(md, up) {
      display: none;
    }
  }
  @include media-break(md, down) {
    flex-wrap: wrap;
    flex-direction: row;
  }
  a {
    margin-right: 10px;
    display: flex;
    align-items: center;
  }
  img {
    width: 50px;
    height: 50px;
    @include media-break(md, down) {
      margin: 10px;
    }
  }
  h6 {
    color: $pure-white;
    font-size: 1.2rem;
    margin-right: 10px;
    font-weight: 200;
  }
}

.comment-modal__wrapper {
  height: 100%;
  @extend .flexbox-space-between-stretch;
  flex-direction: column;
  flex-wrap: nowrap;
  .title {
    display: flex;
  }
  .title::before {
    content: "";
    display: inline-flex;
    width: 7px;
    height: 25px;
    background: lighten($primary-color, 12%);
    border-radius: 5px;
    margin: 0 0 0 12px;
  }
  .content-wrapper {
    background-color: lighten($border-color, 10%);
    padding: 25px 20px;
    margin: 20px 0;
    border-radius: 5px;

    p {
      display: flex;
      justify-content: space-between;
      margin: 10px 0;
      @include media-break(sm, down) {
        display: block;
  
      }

      span {
        font-size: map-get($font-size, "md+");
      }
      div {
        color: $input-color;
        font-size: map-get($font-size, "md+");
      }
    }
  }
}
.comment-modal__message {
  text-align: center;
  color: $input-color;
}

.comment-modal__button-wrapper {
  display: inline-block;
  &--item {
    width: 100%;
    display: inline-block;
    margin: 0 0 10px 0;
    @include media-break(sm, down) {
      display: block;
      width: 100%;
    }
  }
}

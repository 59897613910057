.file-catcher-wrapper {
  border: 1px solid $border-color;
  background-color: lighten($nav-color, 59%);
  margin: 0 0 20px 0;
  border-radius: 10px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  p {
    color: $primary-color;
  }
  input {
    position: absolute;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
}

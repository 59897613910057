.select-wrapper {
  padding: 0 4%;
  margin: 10px 0;
  &--high {
    margin: 25px 0;
    width: 90%;
    margin: auto;
    label {
      display: block;
    font-size: map-get($font-size, "md+");
    padding: 10px 0px 5px 0;
    color: $input-color;
    }
    input {
      font-family: IRANSansWeb;
      font-size: 0.8rem !important;
    }
  }

}
.react-select__single-value {
  font-family: IRANSansWeb;
  font-size: 0.8rem !important;
  color: $pure-black;
}
.react-select__placeholder {
  font-size: 0.8rem !important;
  opacity:50%;
}

.react-select__control {
  border-color: $primary-color !important;

  &--is-focused {
    border-color: $primary-color !important;
    box-shadow: 0px 0px 15px -3px $primary-color !important;
  }
}
.react-select__menu {
  font-size: 0.9rem !important;
}
